<template>
  <div>
    <div class="all_context">
      <div class="all_context_flex">
        <div class="storeCenter_item_top_left">
          <div class="banner_box" v-if="headData.type == '业务内容'">
            <div class="videoUrl" v-if="headData.videoUrl">
              <img class="img" src="../../../assets/image/videoicon.png" alt="" />
            </div>
            <img class="img" v-if="headData.photoUrl" :src="headData.photoUrl" alt="" />
            <video v-else-if="headData.videoUrl" ref="videoPlayer" controls>
              <source :src="headData.videoUrl" type="video/mp4" />
            </video>
            <img class="img" v-else src="../../../assets/image/qf1.png" alt="" />
          </div>
          <div class="banner_box" v-if="headData.type == '宣传物料'">
            <div class="videoUrl" v-if="headData.videoUrl">
              <img class="img" src="../../../assets/image/videoicon.png" alt="" />
            </div>
            <img class="img" v-if="headData.photoUrl" :src="headData.photoUrl" alt="" />
            <video v-else-if="headData.videoUrl" ref="videoPlayer" controls>
              <source :src="headData.videoUrl" type="video/mp4" />
            </video>
          </div>
          <div class="banner_box" v-if="headData.type == '其他'">
            <div class="videoUrl" v-if="headData.videoUrl">
              <img class="img" src="../../../assets/image/videoicon.png" alt="" />
            </div>
            <img class="img" v-if="headData.photoUrl" :src="headData.photoUrl" alt="" />
            <video v-else-if="headData.videoUrl" ref="videoPlayer" controls>
              <source :src="headData.videoUrl" type="video/mp4" />
            </video>
            <img class="img" v-else src="../../../assets/image/qf3.png" alt="" />
          </div>
          <div class="storeCenter_item_top_left_flex">
            <div>
              <span>{{ headData.title }}</span>
            </div>
            <div class="row_margin">
              <span class="clueType_margin">消息类型：</span>

              <img
                v-if="headData.ischeck"
                class="icon_img"
                src="../../../assets/image/txt1.png"
                alt=""
              />
              <span v-if="headData.ischeck">文本</span>
              <img
                v-if="headData.ischend"
                class="icon_img"
                src="../../../assets/image/video1.png"
                alt=""
              />
              <span v-if="headData.ischend">视频</span>
            </div>
            <div class="bottom_flex">
              <div>
                <span>发布日期：{{ headData.sendTime }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="right" @click="goback">
          <el-button type="primary">返回上一级</el-button>
        </div>
      </div>

      <div class="context">
        <div class="state">
          <div>
            <span>该内容总发送</span>
            <span class="color1">{{ total }}</span>
            <span>个群聊</span>
          </div>
          <div class="right">
            <div class="seach_box">
              <el-input placeholder="请输入群聊名称" clearable v-model="query.wechatGroupName" class="input-with-select">
                <el-button class="seach" slot="append" @click="search()">搜索</el-button>
              </el-input>
            </div>
          </div>
        </div>
        <div class="table">
          <el-table
            ref="multipleTable"
            class="min_table"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            v-loading="loading"
            @sort-change="changeSort"
            element-loading-spinner="el-icon-loading"
            element-loading-text="加载中..."
            :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
          >
            <!-- <el-table-column width="200" prop="id" label="编号" align="center"> </el-table-column> -->

            <el-table-column prop="wechatGroupName" label="群聊名称" align="center"> </el-table-column>

            <el-table-column prop="memCnt" label="成员数量" sortable align="center"></el-table-column>
            <el-table-column
              v-if="type == 1"
              prop="sendTime"
              label="发送时间"
              sortable
              align="center"
            ></el-table-column>
            <el-table-column
              v-if="type == 3"
              prop="viewTime"
              label="查看时间"
              sortable
              align="center"
            ></el-table-column>
          </el-table>

          <div class="Pagination">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="query.pageNum"
              :page-sizes="[10, 30, 50]"
              :page-size="query.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import { getMessageGroupPage, getMessageInfoDetail } from '@/api/operatorCenter.js'
export default {
  name: 'shareCount',
  computed: {
    type() {
      return this.$route.query.type
    },
    name() {
      return this.$route.query.name
    },
    id() {
      return this.$route.query.id
    }
  },
  data() {
    //这里存放数据
    return {
      headData: {}, ///头部信息
      // 表格数据
      tableData: [],
      loading: false,
      // 分页总条数
      total: 0,
      ids: [],
      // 表格发送数据
      query: {
        msgId: '',
        orderByParam: '',
        pageNum: 1,
        pageSize: 10,
        wechatGroupName: ''
      },
      userSharenum: 0 //分享查看数
    }
  },

  created() {
    this.getHeadData()
    this.search()
  },
  //方法集合
  methods: {
    handleSizeChange(val) {
      this.query.pageSize = val
      this.search()
    },
    handleCurrentChange(val) {
      this.query.pageNum = val
      this.search()
    },

    // 头部数据
    async getHeadData() {
      const { data: res } = await getMessageInfoDetail({
        id: this.id
      })
      if (res.resultCode == 200) {
        this.headData = res.data
        if (this.headData.contentType) {
          this.headData.contentType = this.headData.contentType.split(',')
          this.headData.contentType.forEach((o) => {
            if (o) {
              if (o == '文本') {
                this.$set(this.headData, 'ischeck', true)
              } else {
                this.$set(this.headData, 'ischend', true)
              }
            }
          })
        }
      }
    },

    // table表格按钮的选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id)
    },
    async search() {
      if(this.type==1){
        this.query.isSend = 1
        this.query.isRead = ''
      }
      if(this.type==2){
        this.query.isSend = 0
         this.query.isRead = ''
      }
      if(this.type==3){
        this.query.isRead = 1
        this.query.isSend = ''
      }
      if(this.type==4){
        this.query.isRead = 0
          this.query.isSend = ''
      }
      this.query.msgId = this.id
      this.loading = true
      const { data: res } = await getMessageGroupPage(this.query)
      if (res.resultCode == 200) {
        this.tableData = res.data.list
        this.total = res.data.total
        this.loading = false
      }
    },
    //返回
    goback() {
      this.$router.go(-1)
    },
    //排序
    changeSort(val) {
      //val里面返回prop和order
      if (val.prop == 'sendTime') {
        if (val.order == 'ascending') {
          //updateAt列升序时
          this.query.orderByParam = 'sendTime asc'
          this.search()
        } else if (val.orderByParam == 'descending') {
          //updateAt列降序时

          this.query.orderByParam = 'send_time desc'
          this.search()
        } else {
          //updateAt列不排序时
        this.query.orderByParam = 'send_time desc'
          this.search()
        }
      } else if (val.prop == 'viewTime') {
        if (val.order == 'ascending') {
          //updateAt列升序时
         this.query.orderByParam = 'view_time asc'
          this.search()
        } else if (val.order == 'descending') {
          //updateAt列降序时
           this.query.orderByParam = 'view_time desc'
          this.search()
        } else {
          //updateAt列不排序时
          this.query.orderByParam = 'view_time desc'
          this.search()
        }
      } else if (val.prop == 'memCnt') {
        if (val.order == 'ascending') {
          //updateAt列升序时
          this.query.orderByParam = 'mem_cnt asc'
          this.search()
        } else if (val.order == 'descending') {
          //updateAt列降序时

            this.query.orderByParam = 'mem_cnt desc'
          this.search()
        } else {
          //updateAt列不排序时
           this.query.orderByParam = 'mem_cnt desc'
          this.search()
        }
      }
    }
  }
}
</script>
  <style scoped lang="less">
.all_context {
  padding: 16px 0px 0 0px;
  .all_context_flex {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
    position: relative;
    margin-bottom: 20px;
    .right {
      position: absolute;
      right: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .total_num {
        margin-bottom: 10px;
        font-size: 30px;
        font-weight: 550;
        color: #ff7d18;
      }
    }
  }
  video::-webkit-media-controls {
    display: none !important;
  }
  .storeCenter_item_top_left {
    display: flex;
    flex-direction: row;
    width: 85%;
    height: 120.73px;

    .banner_box {
      position: relative;
      width: 205.55px;
      height: 115.73px;
      padding-right: 10px;
      .videoUrl {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); /* 垂直和水平居中 */
        img {
          width: 30px;
          height: 30px;
        }
      }
      video {
        width: 205.55px;
        height: 117.73px;
      }
      .img {
        width: 205.55px;
        height: 117.73px;
        border-radius: 4px;
        margin-right: 18px;
      }
      .po_img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 30px;
        height: 30px;
      }

      .activity_state {
        position: absolute;
        width: 51px;
        height: 23px;
        background: #448aff;
        border-radius: 2px;
        color: #fff;
        font-size: 14px;
        text-align: center;
        line-height: 23px;
        top: 5px;
        left: 5px;
      }
    }

    .storeCenter_item_top_left_flex {
      flex: 1;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 10px 0px;
      .row_margin {
        margin: 10px 0px;
        display: flex;
        align-items: center;
        font-size: 16px;
        .icon_img {
          width: 16px;
          height: 16px;
          margin: 0px 5px;
        }

        .clueType_right {
          margin-left: 10px;
          max-width: 340px; /* 固定宽度超出隐藏 */
          overflow: hidden; /*  超出隐藏 */
          text-overflow: ellipsis; /*  超出以 ...显示 */
          display: -webkit-box;
          -webkit-line-clamp: 1; /* // 显示1行 */
          -webkit-box-orient: vertical;
        }
      }
      .bottom_flex {
        display: flex;
        align-items: center;
        .icon_flex {
          display: flex;
          align-items: center;
          margin: 0px 30px;
          .size_img {
            width: 18px;
            height: 18px;
            margin-right: 5px;
          }
        }
      }
    

   

      .address {
        font-size: 16px;
      }
    }
  }
  .seach_box {
    display: flex;
    margin: 15px 0;
    align-items: flex-end;
    .input-with-select {
      width: 400px;
    }

    .seach {
      background-color: #448aff;
      color: #fff;
      border-radius: 0;
      border: 1px solid #448aff;
    }
  }

  .context {
    background-color: #ffffff;
    padding: 0 21px 0 30px;
    border-radius: 8px;
    .color1{
      color: #448aff;
    }
    .state {
      height: 72px;
      border-bottom: 1px dashed #f3f3f3;
      display: flex;
      box-sizing: border-box;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        box-sizing: border-box;
        align-items: center;
        .label {
          font-size: 14px;
          color: #7c7f8e;
        }
        .item {
          color: #333333;
          font-size: 14px;
          cursor: pointer;
          padding: 15px 20px;
        }
      }
      .right {
        display: flex;
        padding-right: 25px;
        f .icon {
          width: 20px;
          height: 20px;

          img {
            width: 100%;
            height: 100%;
          }
        }
        .tex {
          margin-left: 7px;
          color: #595959;
        }
        .time {
          color: #333333;
        }
      }
      /deep/.el-radio {
        margin-right: 0;
      }
    }

    .table {
      .min_table {
        min-height: calc(100vh - 510px);
      }

      .imgbox {
        display: flex;
        justify-content: center;

        .img {
          width: 34px;
          height: 34px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .correlationBox {
        display: flex;
        align-items: center;
        justify-content: center;
        .correlation {
          background-color: #fd6161;
          border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 55px;
          height: 24px;
          color: #fff;
          font-size: 12px;
        }
      }

      .enterpriseIntro {
        // width: 100%;
        // height: 100%;
        width: 376px;
        overflow: hidden; //超出隐藏
        text-overflow: ellipsis; //溢出用省略号显示
        display: -webkit-box; // 将对象作为弹性伸缩盒子模型显示。
        // 控制行数
        -webkit-line-clamp: 2; //超出两行隐藏
        -webkit-box-orient: vertical; // 从上到下垂直排列子元素
        color: #333333;
        position: relative;
      }
      .enterpriseIntroShow {
        position: absolute;
        // right: 0%;
        // bottom: -23%;
        border-radius: 8px;
        // transform: translateX(50%);
        width: 376px;
        padding: 10px 7px 8px 13px;
        background-color: #fff;
        z-index: 999;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
      }

      /deep/.el-table__body-wrapper {
        overflow: visible;
      }
      /deep/.el-table {
        overflow: visible;
      }
    }
    .Pagination {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      padding: 50px 0 50px 0;
    }
  }
}

.add_select {
  .remove_pool {
    box-sizing: border-box;
    width: 348px;
    height: 150px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    box-shadow: 0px 0px 2px #d4d4d4;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    padding: 42px 0 20px 0;
    align-items: center;
    .el-icon-close {
      position: absolute;
      right: 11px;
      top: 11px;
      color: #b6b6b4;
      font-size: 18px;
      cursor: pointer;
    }
    .text {
      margin-bottom: 23px;
    }
    /deep/.el-button {
      width: 112px;
      height: 40px;
    }
  }
  /deep/.el-dialog {
    margin-top: 40vh !important;
  }
}

.correlation {
  /deep/.el-dialog {
    border-radius: 4px;
    position: relative;
  }
  /deep/.el-dialog__body {
    padding: 28px 20px 16px 20px;
  }
  .correlationBox {
    .head {
      position: absolute;
      left: 0;
      top: 12px;
      // transform: translate(-20px, -50px);
      .title {
        border-left: 4px solid #448aff;
        padding-left: 15px;
        font-size: 18px;
        font-weight: 550;
        color: #151515;
      }
    }
    .context {
      .state{
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .input {
        display: flex;
        justify-content: space-around;
        align-items: center;
        .label {
          position: relative;
          font-size: 14px;
          color: #333333;
          span {
            position: absolute;
            right: -9px;
            top: -2px;
            color: #f05252;
          }
        }
        /deep/.el-input {
          width: 322px;
        }
      }
      .button {
        width: 100%;
        display: flex;
        justify-content: center;
        .text {
          width: 64px;
          height: 36px;
          cursor: pointer;
          border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-size: 14px;
          background-color: #448aff;
          margin-top: 31px;
        }
      }
    }
  }
}
</style>
  